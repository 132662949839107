<template>
 <div id="page-container" class="pb-5">
  <div class="content-wrap">
   <PbxDashboard
    :key="pbxElements?.length"
    v-model:selector="periodSelector"
    @action="
     selected_answered_type === 'day'
      ? setDailyChartData()
      : selected_answered_type === 'week'
      ? setWeeklyChartData()
      : selected_answered_type === 'weekNumber'
      ? setWeeklyChartByWeekNumber()
      : selected_answered_type === 'hour'
      ? setHourChartData()
      : setMonthlyChartData()
    "
    @setSelector="setSelector = $event"
    @all-selected="allSelected($event)"
    @button-selector="(...args) => getSelectedButton(...args)"
    @timeSelector="
     selected_answered_type === 'day'
      ? setDailyChartData()
      : selected_answered_type === 'week'
      ? setWeeklyChartData()
      : selected_answered_type === 'weekNumber'
      ? setWeeklyChartByWeekNumber()
      : selected_answered_type === 'hour'
      ? setHourChartData()
      : setMonthlyChartData()
    "
    :buttonSelected="this.buttonSelected"
    :extensions="extensions"
    :dataColumnChartQuarterHourlyCons="
     selected_answered_type === 'day'
      ? dataColumnChartCons
      : selected_answered_type === 'week'
      ? dataColumnChartConsWeek
      : selected_answered_type === 'weekNumber'
      ? dataColumnChartConsWeekNumber
      : selected_answered_type === 'hour'
      ? isHourlyChart
        ? dataColumnChartHourlyCons
        : dataColumnChartQuarterHourlyCons
      : dataColumnChartConsMonth
    "
    :dataLineChartQuarterHourlyCons="
     selected_answered_type === 'day'
      ? dataLineChartCons
      : selected_answered_type === 'week'
      ? dataLineChartConsWeek
      : selected_answered_type === 'weekNumber'
      ? dataLineChartConsWeekNumber
      : selected_answered_type === 'hour'
      ? isHourlyChart
        ? dataLineChartHourlyCons
        : dataLineChartQuarterHourlyCons
      : dataLineChartConsMonth
    "
    :dataColumnChartQuarterHourly="
     is_filtered
      ? selected_answered_type === 'day'
        ? filterNoData(dataColumnChart)
        : selected_answered_type === 'week'
        ? filterNoData(dataColumnChartWeek)
        : selected_answered_type === 'weekNumber'
        ? filterNoData(dataColumnChartWeekNumber)
        : selected_answered_type === 'hour'
        ? isHourlyChart
          ? filterNoData(dataColumnChartHourly)
          : filterNoData(dataColumnChartQuarterHourly)
        : filterNoData(dataColumnChartMonth)
      : selected_answered_type === 'day'
      ? dataColumnChart
      : selected_answered_type === 'week'
      ? dataColumnChartWeek
      : selected_answered_type === 'weekNumber'
      ? dataColumnChartWeekNumber
      : selected_answered_type === 'hour'
      ? isHourlyChart
        ? dataColumnChartHourly
        : dataColumnChartQuarterHourly
      : dataColumnChartMonth
    "
    :dataLineChartQuarterHourly="
     is_filtered
      ? selected_answered_type === 'day'
        ? filterNoData(dataLineChart)
        : selected_answered_type === 'week'
        ? filterNoData(dataLineChartWeek)
        : selected_answered_type === 'weekNumber'
        ? filterNoData(dataLineChartWeekNumber)
        : selected_answered_type === 'hour'
        ? isHourlyChart
          ? filterNoData(dataLineChartHourly)
          : filterNoData(dataLineChartQuarterHourly)
        : filterNoData(dataLineChartMonth)
      : selected_answered_type === 'day'
      ? dataLineChart
      : selected_answered_type === 'week'
      ? dataLineChartWeek
      : selected_answered_type === 'weekNumber'
      ? dataLineChartWeekNumber
      : selected_answered_type === 'hour'
      ? isHourlyChart
        ? dataLineChartHourly
        : dataLineChartQuarterHourly
      : dataLineChartMonth
    "
    :dataColumnChartHourly="
     is_daily ? dataColumnChartCons : dataColumnChartHourly
    "
    :periodSelector="this.periodSelector"
    :timeSelected="this.timeSelected"
    :textMonth="this.textMonth"
    :urlType="urlType"
    :queryElement="queryElement"
    @concatenate="concatenate = $event"
    @setPeriod="(...args) => setPeriod(...args)"
    @is_filtered="is_filtered = $event"
    @selected_answered_type="selected_answered_type = $event"
    :startDate="startDate"
    :endDate="endDate"
    @activeBanner="$emit('activeBanner')"
    @successMsg="$emit('successMsg', $event)"
    @success="$emit('success', $event)"
    @setLoading="$emit('setLoading', $event)"
    @setHourlyChart="isHourlyChart = $event"
    :isHourlyChart="isHourlyChart" />
  </div>
  <!-- <div v-show="extensions.length === 0"><EmptyStateDashboard /></div> -->
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage" />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"];
const daysOfWeekFrench = ["lundi", "mardi", "mercredi", "jeudi", "vendredi"];

export default {
 props: ["urlType"],
 components: {
  EmptyStateDashboard,
  Loading,
  PbxDashboard,
 },
 data() {
  return {
   isHourlyChart: true,
   daysOfWeek,
   daysOfWeekFrench,
   selected_answered_type: "hour",
   is_filtered: true,
   is_daily: true,
   concatenate: false,
   queryElement: [],
   setSelector: undefined,
   isQueryParam: false,
   isLoading: false,
   fullPage: true,
   periodSelector: undefined,
   dataColumnChartQuarterHourly: [],
   dataColumnChartQuarterHourlyCons: [],
   dataColumnChartHourly: [],
   dataColumnChartHourlyCategorie: [],
   dataColumnChartHourlyCons: [],
   dataColumnChart: [],
   dataColumnChartWeek: [],
   dataColumnChartWeekNumber: [],
   dataColumnChartMonth: [],
   dataColumnChartCons: [],
   dataColumnChartConsWeek: [],
   dataColumnChartConsWeekNumber: [],
   dataColumnChartConsMonth: [],

   dataLineChartQuarterHourly: [],
   dataLineChartQuarterHourlyCons: [],
   dataLineChartHourly: [],
   dataLineChartHourlyCategorie: [],
   dataLineChartHourlyCons: [],
   dataLineChart: [],
   dataLineChartWeek: [],
   dataLineChartWeekNumber: [],
   dataLineChartMonth: [],
   dataLineChartCons: [],
   dataLineChartConsWeek: [],
   dataLineChartConsWeekNumber: [],
   dataLineChartConsMonth: [],

   extensions: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: "month",
   textMonth: "",
   data: undefined,
   startDate: null,
   endDate: null,
  };
 },
 methods: {
  setPeriod(...args) {
   this.startDate = args[0];
   this.endDate = args[1];
   this.dataColumnChartQuarterHourly = [];
   this.dataColumnChartQuarterHourlyCons = [];
   this.dataColumnChartHourly = [];
   this.dataColumnChartHourlyCons = [];
   this.dataColumnChart = [];
   this.dataColumnChartWeek = [];
   this.dataColumnChartWeekNumber = [];
   this.dataColumnChartMonth = [];
   this.dataColumnChartCons = [];
   this.dataColumnChartConsWeek = [];
   this.dataColumnChartConsWeekNumber = [];
   this.dataColumnChartConsMonth = [];

   this.dataLineChartQuarterHourly = [];
   this.dataLineChartQuarterHourlyCons = [];
   this.dataLineChartHourly = [];
   this.dataLineChartHourlyCons = [];
   this.dataLineChart = [];
   this.dataLineChartWeek = [];
   this.dataLineChartWeekNumber = [];
   this.dataLineChartMonth = [];
   this.dataLineChartCons = [];
   this.dataLineChartConsWeek = [];
   this.dataLineChartConsWeekNumber = [];
   this.dataLineChartConsMonth = [];

   switch (this.selected_answered_type) {
    case "hour":
     //  this.getDataFromElementForColumn();
     this.setHourChartData();
    case "day":
     //  this.getDataFromElementForColumnDaily();
     this.setDailyChartData();
    case "week":
     //  this.getDataFromElementForColumnWeek();
     this.setWeeklyChartData();
    case "weekNumber":
     //  this.getDataFromElementForColumnWeekNumber();
     this.setWeeklyChartByWeekNumber();
    case "month":
     //  this.getDataFromElementForColumnMonth();
     this.setMonthlyChartData();
    default:
     return "";
   }
   //  this.is_daily
   //   ? this.getDataFromElementForColumnDaily()
   //   : this.getDataFromElementForColumn();
  },
  getButtonSelectedFromQueryPeriod() {
   let period = undefined;
   if (this.$route.query.period) {
    period = this.$route.query.period;
   }
   if (period) {
    if (period.length == 8) {
     this.buttonSelected = "day";
    } else if (period.length == 6) {
     this.buttonSelected = "month";
    } else {
     this.buttonSelected = "week";
    }
   }
  },
  async getKpiData(alias) {
   this.extensions.length = 0;
   this.dataColumnChartQuarterHourly = [];
   this.dataColumnChartQuarterHourlyCons = [];
   this.dataColumnChartHourly = [];
   this.dataColumnChartHourlyCons = [];
   this.dataColumnChart = [];
   this.dataColumnChartWeek = [];
   this.dataColumnChartWeekNumber = [];
   this.dataColumnChartMonth = [];
   this.dataColumnChartCons = [];
   this.dataColumnChartConsWeek = [];
   this.dataColumnChartConsWeekNumber = [];
   this.dataColumnChartConsMonth = [];

   this.dataLineChartQuarterHourly = [];
   this.dataLineChartQuarterHourlyCons = [];
   this.dataLineChartHourly = [];
   this.dataLineChartHourlyCons = [];
   this.dataLineChart = [];
   this.dataLineChartWeek = [];
   this.dataLineChartWeekNumber = [];
   this.dataLineChartMonth = [];
   this.dataLineChartCons = [];
   this.dataLineChartConsWeek = [];
   this.dataLineChartConsWeekNumber = [];
   this.dataLineChartConsMonth = [];

   this.$store.dispatch("pbxElementType", this.urlType);

   let arrayOfElements = [];
   let stringOfElements = null;
   let pbxElements = undefined;

   if (this.startDate && this.endDate && this.pbxElements) {
    if (
     !this.pbxElements &&
     this.$route.query.tableElement &&
     this.isQueryParam
    ) {
     this.queryElement = new Array(this.$route.query.tableElement);
     pbxElements = new Array(this.$route.query.tableElement);
     this.$store.dispatch("pbxElements", pbxElements);
    } else {
     pbxElements = this.pbxElements;
    }
    this.pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });
    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);

    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
     this.hostName
    }/kpi/${this.urlType}/${alias}?start_date=${this.startDate}&end_date=${
     this.endDate
    }&start_time=${this.pbxTimeSelected[0]}&end_time=${
     this.pbxTimeSelected[1]
    }&dn=${stringOfElements}`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });
     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async getPbxData(stringOfElements, sentPeriod, type) {
   if (this.startDate && this.endDate) {
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
     this.hostName
    }/answered?start=${this.startDate}&end=${
     this.endDate
    }&dns=${stringOfElements}&dn_type=${this.urlType === "queue" ? "4" : "0"}`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });
     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  allSelected(val) {
   this.isAllSelected = val;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async setHourChartData() {
   this.setQuarterHourChartData();
   const data = await this.getKpiData("hour");
   const startHour = parseInt(this.pbxTimeSelected[0].slice(0, 2));
   const endHour = parseInt(this.pbxTimeSelected[1].slice(0, 2)) - 1;
   const allHours = [];

   // Create a list of hours from startHour to endHour
   for (let i = startHour; i <= endHour; i++) {
    allHours.push(`${String(i).padStart(2, "0")}`); // Only the hour part (08, 09, ... 18)
   }

   // Create an array to hold the formatted output
   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Iterate over each dn entry
   if (data?.length) {
    data.forEach(item => {
     const dn = item.dn;
     const details = item.details;

     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;

     // Initialize the data structure for the current dn
     const answeredCallsData = Array(allHours.length).fill(0);
     const inboundCallsData = Array(allHours.length).fill(0);
     const waitingTimeData = Array(allHours.length).fill(0);

     // Populate data arrays for answered and inbound calls
     allHours.forEach((hour, index) => {
      let fullHour = hour + ":00";
      const hourData = details[fullHour];

      if (hourData) {
       answeredCallsData[index] = Number(hourData.answered_calls);
       inboundCallsData[index] = Number(hourData.inbound_calls);
       waitingTimeData[index] = Number(hourData.waiting_time);
      }
     });

     // Construct the entry for the current dn
     formattedOutput.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Répondus",
        data: answeredCallsData,
        type: "column",
       },
       {
        name: "Non répondus",
        data: inboundCallsData.map(
         (inbound, index) => inbound - answeredCallsData[index]
        ),
        type: "column",
       },
      ],
     });

     formattedOutputWaiting.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingTimeData.map((waiting, index) =>
         answeredCallsData[index] ? waiting / answeredCallsData[index] : 0
        ),
       },
      ],
     });

     formattedOutputWaitingToConsolidate.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingTimeData.map((waiting, index) => waiting),
       },
      ],
     });
    });
    this.dataColumnChartHourly = this.addMissingPbxElements(formattedOutput);
    this.dataColumnChartHourlyCons = this.consolidateChartData(formattedOutput);

    this.dataLineChartHourly = this.addMissingPbxElementsLine(
     formattedOutputWaiting
    );
    this.dataLineChartHourlyCons = this.consolidateLineChartData(
     formattedOutputWaitingToConsolidate,
     this.consolidateChartData(formattedOutput)
    );
   }
  },
  async setQuarterHourChartData() {
   const data = await this.getKpiData("quarter-hour");
   const startHour = parseInt(this.pbxTimeSelected[0].slice(0, 2));
   const endHour = parseInt(this.pbxTimeSelected[1].slice(0, 2)) - 1;
   const allHours = [];

   // Create a list of quarter-hour time slots from startHour to endHour
   for (let hour = startHour; hour <= endHour; hour++) {
    for (let quarter = 0; quarter < 4; quarter++) {
     allHours.push(
      `${String(hour).padStart(2, "0")}${String(quarter * 15).padStart(2, "0")}`
     );
    }
   }

   // Create an array to hold the formatted output
   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Iterate over each dn entry
   if (data?.length && this.pbxElements?.length) {
    data.forEach(item => {
     const dn = item.dn;
     const details = item.details;

     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;

     // Initialize the data structure for the current dn
     const answeredCallsData = Array(allHours.length).fill(0);
     const inboundCallsData = Array(allHours.length).fill(0);
     const waitingTimeData = Array(allHours.length).fill(0);

     // Populate data arrays for answered and inbound calls
     allHours.forEach((hour, index) => {
      let formatedHour = hour.slice(0, 2) + ":" + hour.slice(-2);
      const hourData = details[`${formatedHour}`];

      if (hourData) {
       answeredCallsData[index] = Number(hourData.answered_calls);
       inboundCallsData[index] = Number(hourData.inbound_calls);
       waitingTimeData[index] = Number(hourData.waiting_time);
      }
     });

     // Construct the entry for the current dn
     formattedOutput.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Répondus",
        data: answeredCallsData,
        type: "column",
       },
       {
        name: "Non répondus",
        data: inboundCallsData.map(
         (inbound, index) => inbound - answeredCallsData[index]
        ),
        type: "column",
       },
       //  {
       //   name: "Waiting",
       //   data: waitingTimeData.map((waiting, index) =>
       //   answeredCallsData[index] ? (waiting / answeredCallsData[index]).toFixed(0) : 0
       //   ),
       //   type: "line",
       //  },
      ],
     });
     formattedOutputWaiting.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingTimeData.map((waiting, index) =>
         answeredCallsData[index] ? waiting / answeredCallsData[index] : 0
        ),
       },
      ],
     });

     formattedOutputWaitingToConsolidate.push({
      name: `${displayName}`,
      labels: allHours,
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingTimeData.map((waiting, index) => waiting),
       },
      ],
     });
    });
    this.dataColumnChartQuarterHourly =
     this.addMissingPbxElements(formattedOutput);
    this.dataColumnChartQuarterHourlyCons =
     this.consolidateChartData(formattedOutput);
    this.dataLineChartQuarterHourly = this.addMissingPbxElementsLine(
     formattedOutputWaiting
    );
    this.dataLineChartQuarterHourlyCons = this.consolidateLineChartData(
     formattedOutputWaitingToConsolidate,
     this.consolidateChartData(formattedOutput)
    );
   }
  },
  async setDailyChartData() {
   const data = await this.getKpiData("day");
   const allDays = this.generateDateRange(this.startDate, this.endDate); // Generate all days in the range

   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Iterate over each item in data
   if (data?.length) {
    data.forEach(item => {
     const dn = item.dn;
     const details = item.details;

     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;

     const answeredCallsData = [];
     const inboundCallsData = [];
     const waitingCallsData = [];

     // Loop through all days and build the data array
     allDays.forEach(day => {
      const dayStr = day.format("YYYY-MM-DD"); // Format day as string (e.g., 2024-08-01)
      const dayData = details[dayStr]; // Check if there is data for this day

      if (dayData) {
       answeredCallsData.push(Number(dayData.answered_calls));
       inboundCallsData.push(Number(dayData.inbound_calls));
       waitingCallsData.push(Number(dayData.waiting_time));
      } else {
       // If no data for the day, add 0s
       answeredCallsData.push(0);
       inboundCallsData.push(0);
       waitingCallsData.push(0);
      }
     });

     // Construct the entry for current dn (handling non répondus as inbound - answered)
     formattedOutput.push({
      name: `${displayName}`,
      labels: allDays.map(day => day.toISOString()), // Format as ISO date strings
      data: [
       {
        name: "Répondus",
        data: answeredCallsData,
       },
       {
        name: "Non répondus",
        data: inboundCallsData.map(
         (inbound, index) => inbound - answeredCallsData[index]
        ),
       },
      ],
     });

     formattedOutputWaiting.push({
      name: `${displayName}`,
      labels: allDays.map(day => day.toISOString()),
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsData.map((waiting, index) =>
         answeredCallsData[index] ? waiting / answeredCallsData[index] : 0
        ),
       },
      ],
     });

     formattedOutputWaitingToConsolidate.push({
      name: `${displayName}`,
      labels: allDays.map(day => day.toISOString()),
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsData.map((waiting, index) => waiting),
       },
      ],
     });
    });

    // Assign the output to the dataColumnChart variable
    this.dataColumnChart = this.addMissingPbxElements(formattedOutput);
    this.dataColumnChartCons = this.consolidateChartData(formattedOutput);
    this.dataLineChart = this.addMissingPbxElementsLine(formattedOutputWaiting);
    this.dataLineChartCons = this.consolidateLineChartData(
     formattedOutputWaitingToConsolidate,
     this.consolidateChartData(formattedOutput)
    );
   }
  },
  async setWeeklyChartData() {
   const data = await this.getKpiData("weekday");

   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Iterate over each item in data
   if (data?.length) {
    data.forEach(item => {
     const dn = item.dn;
     const details = item.details;

     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;

     const answeredCallsData = Array(5).fill(0);
     const inboundCallsData = Array(5).fill(0);
     const waitingCallsData = Array(5).fill(0);

     // Loop through details and fill the data arrays based on weekday_number (1=Mon, 2=Tue, etc.)
     Object.keys(details).forEach(key => {
      const dayData = details[key];
      const weekdayIndex = dayData.weekday_number - 1; // Adjust to 0-based index (1=Lun -> 0)

      // Update answered calls and inbound calls for this weekday
      answeredCallsData[weekdayIndex] = Number(dayData.answered_calls);
      inboundCallsData[weekdayIndex] = Number(dayData.inbound_calls);
      waitingCallsData[weekdayIndex] = Number(dayData.waiting_time);
     });

     // Construct the entry for current dn (handling non répondus as inbound - answered)
     formattedOutput.push({
      name: `${displayName}`,
      labels: this.daysOfWeekFrench, // Use French day names as labels
      data: [
       {
        name: "Répondus",
        data: answeredCallsData,
       },
       {
        name: "Non répondus",
        data: inboundCallsData.map(
         (inbound, index) => inbound - answeredCallsData[index]
        ),
       },
      ],
     });

     formattedOutputWaiting.push({
      name: `${displayName}`,
      labels: this.daysOfWeekFrench, // Use French day names as labels
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsData.map((waiting, index) =>
         answeredCallsData[index] ? waiting / answeredCallsData[index] : 0
        ),
       },
      ],
     });

     formattedOutputWaitingToConsolidate.push({
      name: `${displayName}`,
      labels: this.daysOfWeekFrench, // Use French day names as labels
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsData.map((waiting, index) => waiting),
       },
      ],
     });
    });

    // Assign the output to the dataColumnChartWeek variable
    this.dataColumnChartWeek = this.addMissingPbxElements(formattedOutput);
    this.dataColumnChartConsWeek = this.consolidateChartData(formattedOutput);

    this.dataLineChartWeek = this.addMissingPbxElementsLine(
     formattedOutputWaiting
    );
    this.dataLineChartConsWeek = this.consolidateLineChartData(
     formattedOutputWaitingToConsolidate,
     this.consolidateChartData(formattedOutput)
    );
   }
  },
  async setWeeklyChartByWeekNumber() {
   const data = await this.getKpiData("week");
   console.log("data week ==> ", data);
   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Iterate over each item in data
   if (data?.length) {
    data.forEach(item => {
     const dn = item.dn;
     const details = item.details;

     let displayName = `${dn} - ${
      this.urlType === "queue"
       ? this.pbxMap?.call_queues[dn]?.name
       : this.urlType === "extension"
       ? this.pbxMap?.extensions[dn]
       : dn
     }`;

     const weekLabels = [];
     const answeredCallsData = [];
     const nonAnsweredCallsData = [];
     const waitingCallsData = [];
     const waitingCallsDataToConsolidate = [];

     // Loop through details and fill the arrays for week labels, answered and non answered calls
     Object.keys(details).forEach(key => {
      const weekData = details[key];
      const weekNumber = weekData.week_number + 1; // Week number (e.g., 30, 31, etc.)

      weekLabels.push(String(weekNumber)); // Add week number to labels (converted to string)
      answeredCallsData.push(Number(weekData.answered_calls)); // Add Répondus to the data
      waitingCallsData.push(
       Number(weekData.answered_calls)
        ? Number(weekData.waiting_time) / Number(weekData.answered_calls)
        : 0
      );
      waitingCallsDataToConsolidate.push(Number(weekData.waiting_time));
      nonAnsweredCallsData.push(
       Number(weekData.inbound_calls) - Number(weekData.answered_calls)
      ); // Calculate Non répondus
     });

     // Construct the entry for current dn
     formattedOutput.push({
      name: `${displayName}`,
      labels: weekLabels, // Week numbers as labels
      data: [
       {
        name: "Répondus",
        data: answeredCallsData,
       },
       {
        name: "Non répondus",
        data: nonAnsweredCallsData,
       },
      ],
     });

     formattedOutputWaiting.push({
      name: `${displayName}`,
      labels: weekLabels, // Week numbers as labels
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsData,
       },
      ],
     });

     formattedOutputWaitingToConsolidate.push({
      name: `${displayName}`,
      labels: weekLabels, // Week numbers as labels
      data: [
       {
        name: "Temps d'attente moyen",
        data: waitingCallsDataToConsolidate,
       },
      ],
     });
    });

    // Assign the output to the dataColumnChartWeekNumber variable
    this.dataColumnChartWeekNumber =
     this.addMissingPbxElements(formattedOutput);
    this.dataColumnChartConsWeekNumber =
     this.consolidateChartData(formattedOutput);
    this.dataLineChartWeekNumber = this.addMissingPbxElementsLine(
     formattedOutputWaiting
    );
    this.dataLineChartConsWeekNumber = this.consolidateLineChartData(
     formattedOutputWaitingToConsolidate,
     this.consolidateChartData(formattedOutput)
    );
   }
  },
  async setMonthlyChartData() {
   const data = await this.getKpiData("month");
   const formattedOutput = [];
   const formattedOutputWaiting = [];
   const formattedOutputWaitingToConsolidate = [];

   // Collect all months present in the input data
   const monthSet = new Set();

   // First pass to gather all unique months across all entries
   data?.forEach(item => {
    const details = item.details;
    Object.keys(details).forEach(month => {
     monthSet.add(month); // Add unique month to the set
    });
   });

   // Sort months in ascending order
   const sortedMonths = Array.from(monthSet).sort();

   // Second pass to construct the final output
   data?.forEach(item => {
    const dn = item.dn;
    const details = item.details;

    let displayName = `${dn} - ${
     this.urlType === "queue"
      ? this.pbxMap?.call_queues[dn]?.name
      : this.urlType === "extension"
      ? this.pbxMap?.extensions[dn]
      : dn
    }`;

    const answeredCallsData = [];
    const nonAnsweredCallsData = [];
    const waitingCallsData = [];
    const waitingCallsDataToConsolidate = [];

    // Loop through sorted unique months to make sure we have data for each
    sortedMonths.forEach(month => {
     const monthData = details[month] || {
      inbound_calls: 0,
      answered_calls: 0,
      waiting_time: 0,
     }; // Handle missing months

     answeredCallsData.push(Number(monthData.answered_calls)); // Add Répondus to the data
     waitingCallsData.push(
      Number(monthData.answered_calls)
       ? Number(monthData.waiting_time) / Number(monthData.answered_calls)
       : 0
     ); // Add Répondus to the data
     waitingCallsDataToConsolidate.push(Number(monthData.waiting_time));
     nonAnsweredCallsData.push(
      Number(monthData.inbound_calls) - Number(monthData.answered_calls)
     ); // Calculate Non répondus
    });

    // Construct the entry for current dn
    formattedOutput.push({
     name: `${displayName}`,
     labels: sortedMonths, // Dynamically sorted month numbers as labels
     data: [
      {
       name: "Répondus",
       data: answeredCallsData,
      },
      {
       name: "Non répondus",
       data: nonAnsweredCallsData,
      },
     ],
    });

    formattedOutputWaiting.push({
     name: `${displayName}`,
     labels: sortedMonths, // Week numbers as labels
     data: [
      {
       name: "Temps d'attente moyen",
       data: waitingCallsData,
      },
     ],
    });

    formattedOutputWaitingToConsolidate.push({
     name: `${displayName}`,
     labels: sortedMonths, // Week numbers as labels
     data: [
      {
       name: "Temps d'attente moyen",
       data: waitingCallsDataToConsolidate,
      },
     ],
    });
   });

   // Assign the output to the dataColumnChartMonth variable
   this.dataColumnChartMonth = this.addMissingPbxElements(formattedOutput);
   this.dataColumnChartConsMonth = this.consolidateChartData(formattedOutput);
   this.dataLineChartMonth = this.addMissingPbxElementsLine(
    formattedOutputWaiting
   );
   this.dataLineChartConsMonth = this.consolidateLineChartData(
    formattedOutputWaitingToConsolidate,
    this.consolidateChartData(formattedOutput)
   );
  },
  consolidateChartData(dataColumnCharts) {
   // Initialize a map to store the consolidated data
   const consolidatedDataMap = {
    labels: new Set(),
    answeredCalls: new Map(),
    nonAnsweredCalls: new Map(),
   };

   // Iterate over the input data
   dataColumnCharts.forEach(chart => {
    const { labels, data } = chart;

    // Ensure that 'Répondus' and 'Non répondus' datasets exist
    const answeredData = data.find(d => d.name === "Répondus").data;
    const nonAnsweredData = data.find(d => d.name === "Non répondus").data;

    // Iterate over each label (time period)
    labels.forEach((label, index) => {
     // Add the label to the labels set
     consolidatedDataMap.labels.add(label);

     // Aggregate the answered calls
     if (!consolidatedDataMap.answeredCalls.has(label)) {
      consolidatedDataMap.answeredCalls.set(label, 0);
     }
     consolidatedDataMap.answeredCalls.set(
      label,
      consolidatedDataMap.answeredCalls.get(label) + answeredData[index]
     );

     // Aggregate the non-answered calls
     if (!consolidatedDataMap.nonAnsweredCalls.has(label)) {
      consolidatedDataMap.nonAnsweredCalls.set(label, 0);
     }
     consolidatedDataMap.nonAnsweredCalls.set(
      label,
      consolidatedDataMap.nonAnsweredCalls.get(label) + nonAnsweredData[index]
     );
    });
   });

   // Sort labels (since it's a set)
   const sortedLabels = Array.from(consolidatedDataMap.labels);

   // Construct the final consolidated data
   const consolidatedChartData = {
    labels: sortedLabels,
    name: "Consolidated - All DNs",
    data: [
     {
      name: "Répondus",
      data: sortedLabels.map(
       label => consolidatedDataMap.answeredCalls.get(label) || 0
      ),
     },
     {
      name: "Non répondus",
      data: sortedLabels.map(
       label => consolidatedDataMap.nonAnsweredCalls.get(label) || 0
      ),
     },
    ],
   };

   // Return the consolidated chart data
   return [consolidatedChartData];
  },
  addMissingPbxElements(chartData) {
   this.extensions.length = 0;

   // Assuming this.pbxElements is an array like [809, 802, 803, ...]
   const allHours = chartData.length > 0 ? chartData[0].labels : []; // Get the hour labels from the existing data
   const updatedChartData = [...chartData]; // Clone the original chart data

   // Iterate through pbxElements and add missing dns
   this.pbxElements?.forEach(pbxDn => {
    // Check if the current pbxElement (dn) is already in the chartData
    const existingEntry = updatedChartData.find(entry =>
     entry.name.startsWith(`${pbxDn} -`)
    );

    let displayName = `${pbxDn} - ${
     this.urlType === "queue"
      ? this.pbxMap?.call_queues[pbxDn]?.name
      : this.urlType === "extension"
      ? this.pbxMap?.extensions[pbxDn]
      : pbxDn
    }`;
    this.extensions.push(displayName);
    // If the pbxElement (dn) is missing, add it with empty data
    if (!existingEntry) {
     updatedChartData.push({
      name: displayName,
      labels: allHours, // Use the same labels as the existing entries
      data: [
       {
        name: "Répondus",
        data: [], // Empty data for Répondus
       },
       {
        name: "Non répondus",
        data: [], // Empty data for Non répondus
       },
      ],
     });
    }
   });

   return updatedChartData;
  },
  consolidateLineChartData(dataColumnCharts, consCallsData) {
   // Initialize a map to store the consolidated data
   const consolidatedDataMap = {
    labels: new Set(),
    waitingCalls: new Map(),
    answeredCalls: new Map(),
   };

   // Iterate over the input data
   dataColumnCharts.forEach(chart => {
    const { labels, data } = chart;

    // Ensure that 'Répondus' and 'Non répondus' datasets exist
    const waitingData = data.find(
     d => d.name === "Temps d'attente moyen"
    )?.data;

    // Iterate over each label (time period)
    labels.forEach((label, index) => {
     // Add the label to the labels set
     consolidatedDataMap.labels.add(label);

     // Aggregate the waiting calls
     if (!consolidatedDataMap.waitingCalls?.has(label)) {
      consolidatedDataMap.waitingCalls.set(label, 0);
     }
     consolidatedDataMap.waitingCalls.set(
      label,
      consolidatedDataMap.waitingCalls.get(label) + waitingData[index]
     );
    });
   });

   consCallsData.forEach(chart => {
    const { labels, data } = chart;

    // Ensure that 'Répondus' and 'Non répondus' datasets exist
    const answeredData = data.find(d => d.name === "Répondus")?.data;

    // Iterate over each label (time period)
    labels.forEach((label, index) => {
     // Aggregate the waiting calls
     if (!consolidatedDataMap.answeredCalls?.has(label)) {
      consolidatedDataMap.answeredCalls.set(label, 0);
     }
     consolidatedDataMap.answeredCalls.set(
      label,
      consolidatedDataMap.answeredCalls.get(label) + answeredData[index]
     );
    });
   });

   // Sort labels (since it's a set)
   const sortedLabels = Array.from(consolidatedDataMap.labels);

   // Construct the final consolidated data
   const consolidatedChartData = {
    labels: sortedLabels,
    name: "Consolidated - All DNs",
    data: [
     {
      name: "Temps d'attente moyen",
      data: sortedLabels.map(label =>
       consolidatedDataMap.answeredCalls.get(label) > 0
        ? consolidatedDataMap.waitingCalls.get(label) /
          consolidatedDataMap.answeredCalls.get(label)
        : 0
      ),
     },
    ],
   };

   // Return the consolidated chart data
   return [consolidatedChartData];
  },
  addMissingPbxElementsLine(chartData) {
   const allHours = chartData.length > 0 ? chartData[0].labels : []; // Get the hour labels from the existing data
   const updatedChartData = [...chartData]; // Clone the original chart data

   // Iterate through pbxElements and add missing dns
   this.pbxElements?.forEach(pbxDn => {
    // Check if the current pbxElement (dn) is already in the chartData
    const existingEntry = updatedChartData.find(entry =>
     entry.name.startsWith(`${pbxDn} -`)
    );

    let displayName = `${pbxDn} - ${
     this.urlType === "queue"
      ? this.pbxMap?.call_queues[pbxDn]?.name
      : this.urlType === "extension"
      ? this.pbxMap?.extensions[pbxDn]
      : pbxDn
    }`;
    // If the pbxElement (dn) is missing, add it with empty data
    if (!existingEntry) {
     updatedChartData.push({
      name: displayName,
      labels: allHours, // Use the same labels as the existing entries
      data: [
       {
        name: "Temps d'attente moyen",
        data: [], // Empty data for Répondus
       },
      ],
     });
    }
   });

   return updatedChartData;
  },
  async getDataFromElementForColumnDaily(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;

   this.dataColumnChart = [];
   this.dataColumnChartCons = [];

   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";

   this.extensions.length = 0;

   let pbxElements = undefined;

   if (
    !this.pbxElements &&
    this.$route.query.tableElement &&
    this.isQueryParam
   ) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements);
     this.data = res;
    }

    let keys = [];
    let keysConc = [];

    if (this.timeSelected.length > 0) {
     if (
      this.timeSelected[0].slice(-2) === "00" &&
      this.timeSelected[1].slice(-2) === "00"
     ) {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
     let startTimeSelected = this.timeSelected[0];
     let endTimeSelected = this.timeSelected[1];
    }

    keys = Object.keys(res.daily);
    keysConc = Object.keys(res["daily*"]);

    let days = [];
    days = res.days;

    let formatedDays = [];

    days.forEach(day => {
     formatedDays.push(this.formatDateApiRequest(day));
    });

    if (keys.length > 0) {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      dataObjUn = {};
      dataObjUnCons = {};
      dataObjAns = {};
      dataObjAnsCons = {};
      dataObjAns["name"] = "Répondus";
      dataObjAns["data"] = [];
      dataObjAnsCons["name"] = "Répondus";
      dataObjAnsCons["data"] = [];
      dataObjUn["name"] = "Non répondus";
      dataObjUn["data"] = [];
      dataObjUnCons["name"] = "Non répondus";
      dataObjUnCons["data"] = [];
      obj["data"] = [];
      objCons["data"] = [];
      obj["labels"] = [];
      objCons["labels"] = [];

      let filledArrayDataAns = new Array(days.length).fill(0);
      let filledArrayDataUns = new Array(days.length).fill(0);
      let filledArrayDataAnsCons = new Array(days.length).fill(0);
      let filledArrayDataUnsCons = new Array(days.length).fill(0);
      let filledArrayDataAnsHourly = [];
      let filledArrayDataAnsHourlyCons = [];
      let filledArrayDataUnsHourly = [];
      let filledArrayDataUnsHourlyCons = [];

      keys.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0];
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] == arrayOfElements[arrIndex]) {
        obj["name"] = splitElement[2] + " - " + elementName;
        if (splitElement[3] === "answered") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataAns.splice(
            index,
            1,
            parseInt(res.daily[element]) <=
             parseInt(res["daily"][element.replace("answered", "calls")])
             ? parseInt(res.daily[element])
             : parseInt(res["daily"][element.replace("answered", "calls")])
           );
          }
         }
         dataObjAns["name"] = "Répondus";
         dataObjAns["data"] = filledArrayDataAns;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataUns.splice(
            index,
            1,
            parseInt(res["daily"][element]) -
             (parseInt(res["daily"][element.replace("calls", "answered")])
              ? parseInt(res["daily"][element.replace("calls", "answered")]) <=
                parseInt(res["daily"][element])
                ? parseInt(res["daily"][element.replace("calls", "answered")])
                : parseInt(res["daily"][element])
              : 0)
           );
          }
         }
         dataObjUn["name"] = "Non répondus";
         dataObjUn["data"] = filledArrayDataUns;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysConc.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0];
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] === "*") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataAnsCons.splice(
            index,
            1,
            parseInt(res["daily*"][element]) <=
             parseInt(res["daily*"][element.replace("answered", "calls")])
             ? parseInt(res["daily*"][element])
             : parseInt(res["daily*"][element.replace("answered", "calls")])
           );
          }
         }
         dataObjAnsCons["name"] = "Répondus";
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < formatedDays.length; index++) {
          const elementDay = formatedDays[index];
          if (elementDay === requestDay) {
           filledArrayDataUnsCons.splice(
            index,
            1,
            parseInt(res["daily*"][element]) -
             (parseInt(res["daily*"][element.replace("calls", "answered")])
              ? parseInt(res["daily*"][element.replace("calls", "answered")]) <=
                parseInt(res["daily*"][element])
                ? parseInt(res["daily*"][element.replace("calls", "answered")])
                : parseInt(res["daily*"][element])
              : 0)
           );
          }
         }
         dataObjUnCons["name"] = "Non répondus";
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      obj["labels"] = days;
      objCons["labels"] = days;

      obj["data"].splice(0, 1, dataObjAns);
      obj["data"].splice(1, 1, dataObjUn);

      objCons["data"].splice(0, 1, dataObjAnsCons);
      objCons["data"].splice(1, 1, dataObjUnCons);

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChart.splice(arrIndex, 1, obj);
      this.dataColumnChartCons.splice(0, 1, objCons);
     }
    } else {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      let elementName = "";
      elementName =
       this.urlType === "queue"
        ? res.map[arrayOfElements[arrIndex]]?.name
        : res.map[arrayOfElements[arrIndex]];

      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["name"] = "cons";
      obj["labels"] = [];
      objCons["labels"] = [];
      obj["labels"] = [];
      obj["data"] = [];

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChart.splice(arrIndex, 1, obj);
      this.dataColumnChartCons.splice(0, 1, objCons);
     }
    }
   }
   this.$emit("setLoading", false);
  },
  async getDataFromElementForColumn(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let uniqueTimeLabel = ["0600", "2000"];

   let res = undefined;

   this.dataColumnChartQuarterHourly = [];
   this.dataColumnChartQuarterHourlyCons = [];
   this.dataColumnChartHourly = [];
   this.dataColumnChartHourlyCons = [];
   let period = this.currentMonthAndYearForPbx;
   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";
   this.extensions.length = 0;

   if (this.setSelector) {
    period = this.setSelector;
   }

   let pbxElements = undefined;

   if (
    !this.pbxElements &&
    this.$route.query.tableElement &&
    this.isQueryParam
   ) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let objHourly = {};
    let objHourlyCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjAnsHourly = {};
    let dataObjAnsHourlyCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};
    let dataObjUnHourly = {};
    let dataObjUnHourlyCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements, period, type);
     this.data = res;
    }

    this.dataColumnChartQuarterHourly = [];
    this.dataColumnChartQuarterHourlyCons = [];
    this.dataColumnChartHourly = [];
    this.dataColumnChartHourlyCons = [];
    let keys = [];
    let keysConc = [];
    let keysHourly = [];
    let keysHourlyConc = [];
    let timeSelectedIsHour = false;
    let timeSelectedIsQuarter = false;
    let startTimeSelected = undefined;
    let endTimeSelected = undefined;

    if (this.timeSelected.length > 0) {
     if (
      this.timeSelected[0].slice(-2) === "00" &&
      this.timeSelected[1].slice(-2) === "00"
     ) {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
     startTimeSelected = this.timeSelected[0];
     endTimeSelected = this.timeSelected[1];
    }

    keys = Object.keys(res.sum_quarter_hourly);
    keysConc = Object.keys(res["sum_quarter_hourly*"]);
    keysHourly = Object.keys(res.sum_hourly);
    keysHourlyConc = Object.keys(res["sum_hourly*"]);

    let timeLabelRange = [];
    for (let index = 0; index < keysHourly.length; index++) {
     const element = keysHourly[index];
     if (element.split(":")[0].length == 10) {
      let time = element.split(":")[0].slice(-2);
      if (timeLabelRange.length) {
       if (!timeLabelRange[1]) {
        timeLabelRange.splice(1, 1, time);
       }
       if (parseInt(time) < parseInt(timeLabelRange[0])) {
        timeLabelRange.splice(0, 1, time);
       }
       if (parseInt(time) > parseInt(timeLabelRange[1])) {
        timeLabelRange.splice(1, 1, time);
       }
      } else {
       timeLabelRange.push(time);
      }
     }
    }
    let timeLabelStart =
     parseInt(timeLabelRange[0]) + parseInt(this.hostTimeZone) + "00";
    let timeLabelEnd =
     parseInt(timeLabelRange[1]) + 1 + parseInt(this.hostTimeZone) + "00";
    uniqueTimeLabel = [timeLabelStart, timeLabelEnd];

    if (keys.length > 0 || keysHourly.length > 0) {
     let timeLabels = [];
     let timeLabelsCons = [];
     let timeLabelsHourly = [];
     let timeLabelsHourlyCons = [];

     if (timeSelectedIsHour || timeSelectedIsQuarter) {
      timeLabels = await this.getTimeLabelRangeStartAndEnd(
       startTimeSelected,
       endTimeSelected
      );
      timeLabelsCons = timeLabels;
      timeLabelsHourly = await this.getTimeLabelRangeHourlyStartAndEnd(
       startTimeSelected,
       endTimeSelected
      );
      timeLabelsHourlyCons = timeLabelsHourly;
     } else {
      timeLabels = await this.getTimeLabelRangeStartAndEnd(
       uniqueTimeLabel[0],
       uniqueTimeLabel[1]
      );
      timeLabelsCons = timeLabels;
      timeLabelsHourly = await this.getTimeLabelRangeHourlyStartAndEnd(
       uniqueTimeLabel[0],
       uniqueTimeLabel[1]
      );
      timeLabelsHourlyCons = timeLabelsHourly;
     }
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      objHourly = {};
      objHourlyCons = {};
      dataObjUnHourly = {};
      dataObjUnHourlyCons = {};
      dataObjUn = {};
      dataObjUnCons = {};
      dataObjAnsHourly = {};
      dataObjAnsHourlyCons = {};
      dataObjAns = {};
      dataObjAnsCons = {};
      dataObjAns["name"] = "Répondus";
      dataObjAns["data"] = [];
      dataObjAnsCons["name"] = "Répondus";
      dataObjAnsCons["data"] = [];
      dataObjUn["name"] = "Non répondus";
      dataObjUn["data"] = [];
      dataObjUnCons["name"] = "Non répondus";
      dataObjUnCons["data"] = [];
      obj["data"] = [];
      objCons["data"] = [];
      objHourly["data"] = [];
      objHourlyCons["data"] = [];
      obj["labels"] = [];
      objCons["labels"] = [];
      objHourly["labels"] = [];
      objHourlyCons["labels"] = [];

      let filledArrayDataAns = [];
      let filledArrayDataUns = [];
      let filledArrayDataAnsCons = [];
      let filledArrayDataUnsCons = [];
      let filledArrayDataAnsHourly = [];
      let filledArrayDataAnsHourlyCons = [];
      let filledArrayDataUnsHourly = [];
      let filledArrayDataUnsHourlyCons = [];

      filledArrayDataAns = new Array(timeLabels.length).fill(0);
      filledArrayDataAnsCons = new Array(timeLabelsCons.length).fill(0);
      filledArrayDataAnsHourly = new Array(timeLabelsHourly.length).fill(0);
      filledArrayDataUnsHourly = new Array(timeLabelsHourly.length).fill(0);
      filledArrayDataAnsHourlyCons = new Array(
       timeLabelsHourlyCons.length
      ).fill(0);
      filledArrayDataUns = new Array(timeLabels.length).fill(0);
      filledArrayDataUnsCons = new Array(timeLabelsCons.length).fill(0);
      filledArrayDataUnsHourlyCons = new Array(
       timeLabelsHourlyCons.length
      ).fill(0);

      keys.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let splitedHour = splitElement[0].slice(-4);
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (
        splitElement[2] == arrayOfElements[arrIndex] &&
        splitElement[0].length == 12
       ) {
        obj["name"] = splitElement[2] + " - " + elementName;
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabels.length; index++) {
          const elementTest = timeLabels[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataAns.splice(
            index,
            1,
            parseInt(res.sum_quarter_hourly[element]) <=
             parseInt(
              res["sum_quarter_hourly"][element.replace("answered", "calls")]
             )
             ? parseInt(res.sum_quarter_hourly[element])
             : parseInt(
                res["sum_quarter_hourly"][element.replace("answered", "calls")]
               )
           );
          }
         }
         dataObjAns["name"] = "Répondus";
         dataObjAns["data"] = filledArrayDataAns;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabels.length; index++) {
          const elementTest = timeLabels[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataUns.splice(
            index,
            1,
            parseInt(res["sum_quarter_hourly"][element]) -
             (parseInt(
              res["sum_quarter_hourly"][element.replace("calls", "answered")]
             )
              ? parseInt(
                 res["sum_quarter_hourly"][element.replace("calls", "answered")]
                ) <= parseInt(res["sum_quarter_hourly"][element])
                ? parseInt(
                   res["sum_quarter_hourly"][
                    element.replace("calls", "answered")
                   ]
                  )
                : parseInt(res["sum_quarter_hourly"][element])
              : 0)
           );
          }
         }
         dataObjUn["name"] = "Non répondus";
         dataObjUn["data"] = filledArrayDataUns;
        }
       } else if (splitElement[2] === "*") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsCons.length; index++) {
          const elementTest = timeLabelsCons[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataAnsCons.splice(
            index,
            1,
            parseInt(res.sum_quarter_hourly[element])
           );
          }
         }
         dataObjAnsCons["name"] = "Répondus";
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabelsCons.length; index++) {
          const elementTest = timeLabelsCons[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataUnsCons.splice(
            index,
            1,
            parseInt(res.sum_quarter_hourly[element]) -
             (parseInt(
              res.sum_quarter_hourly[element.replace("calls", "answered")]
             )
              ? parseInt(
                 res.sum_quarter_hourly[element.replace("calls", "answered")]
                )
              : 0)
           );
          }
         }
         dataObjUnCons["name"] = "Non répondus";
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysHourly.forEach((element, index) => {
       let splitElement = element.split(":");
       let splitedHour = splitElement[0].slice(-2);
       if (
        splitElement[2] == arrayOfElements[arrIndex] &&
        splitElement[0].length == 10
       ) {
        objHourly["name"] = splitElement[2];
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsHourly.length; index++) {
          const elementTest = timeLabelsHourly[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataAnsHourly.splice(
            index,
            1,
            parseInt(res.sum_hourly[element]) <=
             parseInt(res["sum_hourly"][element.replace("answered", "calls")])
             ? parseInt(res.sum_hourly[element])
             : parseInt(res["sum_hourly"][element.replace("answered", "calls")])
           );
          }
         }
         dataObjAnsHourly["name"] = "Répondus";
         dataObjAnsHourly["data"] = filledArrayDataAnsHourly;
        } else if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabelsHourly.length; index++) {
          const elementTest = timeLabelsHourly[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataUnsHourly.splice(
            index,
            1,
            parseInt(res.sum_hourly[element]) -
             (parseInt(res.sum_hourly[element.replace("calls", "answered")])
              ? parseInt(res.sum_hourly[element.replace("calls", "answered")])
              : 0)
           );
          }
         }
         dataObjUnHourly["name"] = "Non répondus";
         dataObjUnHourly["data"] = filledArrayDataUnsHourly;
        }
       } else if (splitElement[2] === "*") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsHourlyCons.length; index++) {
          const elementTest = timeLabelsHourlyCons[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataAnsHourlyCons.splice(
            index,
            1,
            parseInt(res.sum_hourly[element])
           );
          }
         }
         dataObjAnsHourlyCons["name"] = "Répondus";
         dataObjAnsHourlyCons["data"] = filledArrayDataAnsHourlyCons;
         objHourlyCons["data"].splice(0, 1, dataObjAnsHourlyCons);
        } else if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabelsHourlyCons.length; index++) {
          const elementTest = timeLabelsHourlyCons[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataUnsHourlyCons.splice(
            index,
            1,
            parseInt(res.sum_hourly[element]) -
             (parseInt(res.sum_hourly[element.replace("calls", "answered")])
              ? parseInt(res.sum_hourly[element.replace("calls", "answered")])
              : 0)
           );
          }
         }
         dataObjUnHourlyCons["name"] = "Non répondus";
         dataObjUnHourlyCons["data"] = filledArrayDataUnsHourlyCons;
         objHourlyCons["data"].splice(1, 1, dataObjUnHourlyCons);
        }
       } else {
        // ! in case there is key but no data for element
        let elementName = "";
        elementName =
         this.urlType === "queue"
          ? res.map[arrayOfElements[arrIndex]]?.name
          : res.map[arrayOfElements[arrIndex]];
        objHourly["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysConc.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] === "*" && splitElement[0].length == 12) {
        let splitedHour = splitElement[0].slice(-4);
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsCons.length; index++) {
          const elementTest = timeLabelsCons[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataAnsCons.splice(
            index,
            1,
            parseInt(res["sum_quarter_hourly*"][element]) <=
             parseInt(
              res["sum_quarter_hourly*"][element.replace("answered", "calls")]
             )
             ? parseInt(res["sum_quarter_hourly*"][element])
             : parseInt(
                res["sum_quarter_hourly*"][element.replace("answered", "calls")]
               )
           );
          }
         }
         dataObjAnsCons["name"] = "Répondus";
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabelsCons.length; index++) {
          const elementTest = timeLabelsCons[index];
          if (
           (
            "0" +
            String(
             parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone)
            )
           ).slice(-2) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataUnsCons.splice(
            index,
            1,
            parseInt(res["sum_quarter_hourly*"][element]) -
             (parseInt(
              res["sum_quarter_hourly*"][element.replace("calls", "answered")]
             )
              ? parseInt(
                 res["sum_quarter_hourly*"][
                  element.replace("calls", "answered")
                 ]
                ) <= parseInt(res["sum_quarter_hourly*"][element])
                ? parseInt(
                   res["sum_quarter_hourly*"][
                    element.replace("calls", "answered")
                   ]
                  )
                : parseInt(res["sum_quarter_hourly*"][element])
              : 0)
           );
          }
         }
         dataObjUnCons["name"] = "Non répondus";
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysHourlyConc.forEach((element, index) => {
       let splitElement = element.split(":");
       if (splitElement[2] === "*" && splitElement[0].length == 10) {
        let splitedHour = splitElement[0].slice(-2);
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsHourlyCons.length; index++) {
          const elementTest = timeLabelsHourlyCons[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataAnsHourlyCons.splice(
            index,
            1,
            parseInt(res["sum_hourly*"][element])
           );
          }
         }
         dataObjAnsHourlyCons["name"] = "Répondus";
         dataObjAnsHourlyCons["data"] = filledArrayDataAnsHourlyCons;
         objHourlyCons["data"].splice(0, 1, dataObjAnsHourlyCons);
        } else if (splitElement[3] === "calls") {
         for (let index = 0; index < timeLabelsHourlyCons.length; index++) {
          const elementTest = timeLabelsHourlyCons[index];
          if (
           (
            "0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))
           ).slice(-2) === elementTest
          ) {
           filledArrayDataUnsHourlyCons.splice(
            index,
            1,
            parseInt(res["sum_hourly*"][element]) -
             (parseInt(res["sum_hourly*"][element.replace("calls", "answered")])
              ? parseInt(
                 res["sum_hourly*"][element.replace("calls", "answered")]
                )
              : 0)
           );
          }
         }
         dataObjUnHourlyCons["name"] = "Non répondus";
         dataObjUnHourlyCons["data"] = filledArrayDataUnsHourlyCons;
         objHourlyCons["data"].splice(1, 1, dataObjUnHourlyCons);
        }
       } else {
        // ! in case there is key but no data for element
        let elementName = "";
        elementName =
         this.urlType === "queue"
          ? res.map[arrayOfElements[arrIndex]]?.name
          : res.map[arrayOfElements[arrIndex]];
        objHourly["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      obj["labels"] = timeLabels;
      objHourly["labels"] = timeLabelsHourly;
      objCons["labels"] = timeLabelsCons;
      objHourlyCons["labels"] = timeLabelsHourlyCons;

      obj["data"].splice(0, 1, dataObjAns);
      obj["data"].splice(1, 1, dataObjUn);

      objHourly["data"].splice(0, 1, dataObjAnsHourly);
      objHourly["data"].splice(1, 1, dataObjUnHourly);

      objCons["data"].splice(0, 1, dataObjAnsCons);
      objCons["data"].splice(1, 1, dataObjUnCons);

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChartQuarterHourly.splice(arrIndex, 1, obj);
      this.dataColumnChartHourly.splice(arrIndex, 1, objHourly);
      this.dataColumnChartQuarterHourlyCons.splice(0, 1, objCons);
      this.dataColumnChartHourlyCons.splice(0, 1, objHourlyCons);
     }
    } else {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objHourly = {};
      objCons = {};
      let elementName = "";
      elementName =
       this.urlType === "queue"
        ? res.map[arrayOfElements[arrIndex]]?.name
        : res.map[arrayOfElements[arrIndex]];

      objHourly["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["name"] = "cons";
      objHourlyCons["name"] = "cons";
      obj["labels"] = [];
      objHourly["labels"] = [];
      objCons["labels"] = [];
      objHourlyCons["labels"] = [];
      obj["labels"] = [];
      obj["data"] = [];

      objHourly["data"] = objCons["data"] = obj["data"];

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChartQuarterHourly.splice(arrIndex, 1, obj);
      this.dataColumnChartHourly.splice(arrIndex, 1, objHourly);
      this.dataColumnChartQuarterHourlyCons.splice(0, 1, objCons);
      this.dataColumnChartHourlyCons.splice(0, 1, objHourlyCons);
     }
    }
   }
   this.$emit("setLoading", false);
  },
  async getDataFromElementForColumnWeek(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;

   this.dataColumnChartWeek = [];
   this.dataColumnChartConsWeek = [];

   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";

   this.extensions.length = 0;

   let pbxElements = undefined;

   if (
    !this.pbxElements &&
    this.$route.query.tableElement &&
    this.isQueryParam
   ) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let objHourly = {};
    let objHourlyCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjAnsHourly = {};
    let dataObjAnsHourlyCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};
    let dataObjUnHourly = {};
    let dataObjUnHourlyCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements);
     this.data = res;
    }
    this.dataColumnChartWeek = [];
    this.dataColumnChartConsWeek = [];

    let keys = [];
    let keysHourly = [];
    let keysWeekDay = [];
    let keysWeekDayCons = [];
    let timeSelectedIsHour = false;
    let timeSelectedIsQuarter = false;
    let startTimeSelected = undefined;
    let endTimeSelected = undefined;

    if (this.timeSelected.length > 0) {
     if (
      this.timeSelected[0].slice(-2) === "00" &&
      this.timeSelected[1].slice(-2) === "00"
     ) {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
     startTimeSelected = this.timeSelected[0];
     endTimeSelected = this.timeSelected[1];
    }

    keysWeekDay = Object.keys(res.day_of_week);
    keysWeekDayCons = Object.keys(res["day_of_week*"]);
    let timeLabelRange = [];
    for (let index = 0; index < keysHourly.length; index++) {
     const element = keysHourly[index];
     let time = element.split(":")[2].slice(-2);
     if (timeLabelRange.length) {
      if (!timeLabelRange[1]) {
       timeLabelRange.splice(1, 1, time);
      }
      if (parseInt(time) < parseInt(timeLabelRange[0])) {
       timeLabelRange.splice(0, 1, time);
      }
      if (parseInt(time) > parseInt(timeLabelRange[1])) {
       timeLabelRange.splice(1, 1, time);
      }
     } else {
      timeLabelRange.push(time);
     }
    }

    if (keysWeekDay.length > 0) {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      dataObjUn = {};
      dataObjUnCons = {};
      dataObjAns = {};
      dataObjAnsCons = {};
      dataObjAns["name"] = "answered";
      dataObjAns["data"] = [];
      dataObjAnsCons["name"] = "answered";
      dataObjAnsCons["data"] = [];
      dataObjUn["name"] = "unanswered";
      dataObjUn["data"] = [];
      dataObjUnCons["name"] = "unanswered";
      dataObjUnCons["data"] = [];
      obj["data"] = [];
      objCons["data"] = [];
      obj["labels"] = [];
      objCons["labels"] = [];

      let filledArrayDataAns = [];
      let filledArrayDataUns = [];
      let filledArrayDataAnsCons = [];
      let filledArrayDataUnsCons = [];

      filledArrayDataAns = new Array(this.daysOfWeek.length).fill(0);
      filledArrayDataAnsCons = new Array(this.daysOfWeek.length).fill(0);
      filledArrayDataUns = new Array(this.daysOfWeek.length).fill(0);
      filledArrayDataUnsCons = new Array(this.daysOfWeek.length).fill(0);

      keysWeekDay.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0].slice(-3);
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] == arrayOfElements[arrIndex]) {
        obj["name"] = splitElement[2] + " - " + elementName;
        if (splitElement[3] === "answered") {
         for (let index = 0; index < this.daysOfWeek.length; index++) {
          const elementDay = this.daysOfWeek[index];
          if (elementDay === requestDay) {
           filledArrayDataAns.splice(
            index,
            1,
            parseInt(res.day_of_week[element]) <=
             parseInt(res["day_of_week"][element.replace("answered", "calls")])
             ? parseInt(res.day_of_week[element])
             : parseInt(
                res["day_of_week"][element.replace("answered", "calls")]
               )
           );
          }
         }
         dataObjAns["name"] = "Répondus";
         dataObjAns["data"] = filledArrayDataAns;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < this.daysOfWeek.length; index++) {
          const elementDay = this.daysOfWeek[index];
          if (elementDay === requestDay) {
           filledArrayDataUns.splice(
            index,
            1,
            parseInt(res["day_of_week"][element]) -
             (parseInt(res["day_of_week"][element.replace("calls", "answered")])
              ? parseInt(
                 res["day_of_week"][element.replace("calls", "answered")]
                ) <= parseInt(res["day_of_week"][element])
                ? parseInt(
                   res["day_of_week"][element.replace("calls", "answered")]
                  )
                : parseInt(res["day_of_week"][element])
              : 0)
           );
          }
         }
         dataObjUn["name"] = "Non répondus";
         dataObjUn["data"] = filledArrayDataUns;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });

      keysWeekDayCons.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let requestDay = splitElement[0].slice(-3);
       elementName =
        this.urlType === "queue"
         ? res.map[arrayOfElements[arrIndex]]?.name
         : res.map[arrayOfElements[arrIndex]];
       if (splitElement[2] === "*") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < this.daysOfWeek.length; index++) {
          const elementDay = this.daysOfWeek[index];
          if (elementDay === requestDay) {
           filledArrayDataAnsCons.splice(
            index,
            1,
            parseInt(res["day_of_week*"][element]) <=
             parseInt(res["day_of_week*"][element.replace("answered", "calls")])
             ? parseInt(res["day_of_week*"][element])
             : parseInt(
                res["day_of_week*"][element.replace("answered", "calls")]
               )
           );
          }
         }
         dataObjAnsCons["name"] = "Répondus";
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "calls") {
         for (let index = 0; index < this.daysOfWeek.length; index++) {
          const elementDay = this.daysOfWeek[index];
          if (elementDay === requestDay) {
           filledArrayDataUnsCons.splice(
            index,
            1,
            parseInt(res["day_of_week*"][element]) -
             (parseInt(
              res["day_of_week*"][element.replace("calls", "answered")]
             )
              ? parseInt(
                 res["day_of_week*"][element.replace("calls", "answered")]
                ) <= parseInt(res["day_of_week*"][element])
                ? parseInt(
                   res["day_of_week*"][element.replace("calls", "answered")]
                  )
                : parseInt(res["day_of_week*"][element])
              : 0)
           );
          }
         }
         dataObjUnCons["name"] = "Non répondus";
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });
      obj["labels"] = this.daysOfWeekFrench;
      objCons["labels"] = this.daysOfWeekFrench;

      obj["data"].splice(0, 1, dataObjAns);
      obj["data"].splice(1, 1, dataObjUn);

      objCons["data"].splice(0, 1, dataObjAnsCons);
      objCons["data"].splice(1, 1, dataObjUnCons);

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChartWeek.splice(arrIndex, 1, obj);
      this.dataColumnChartConsWeek.splice(0, 1, objCons);
     }
    } else {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objHourly = {};
      objCons = {};
      let elementName = "";
      elementName = res.map[arrayOfElements[arrIndex]];

      objHourly["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["name"] = "cons";
      objHourlyCons["name"] = "cons";
      obj["labels"] = [];
      objHourly["labels"] = [];
      objCons["labels"] = [];
      objHourlyCons["labels"] = [];
      obj["labels"] = [];
      obj["data"] = [];

      objHourly["data"] = objCons["data"] = obj["data"];

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChartWeek.splice(arrIndex, 1, obj);
      this.dataColumnChartConsWeek.splice(0, 1, objCons);
     }
    }
   }
   this.$emit("setLoading", false);
  },
  async getDataFromElementForColumnMonth(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;
   this.dataColumnChartMonth = [];
   this.dataColumnChartConsMonth = [];

   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";

   this.extensions.length = 0;

   let pbxElements = undefined;

   if (
    !this.pbxElements &&
    this.$route.query.tableElement &&
    this.isQueryParam
   ) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements);
     this.data = res;
    }

    this.dataColumnChartMonth = [];
    this.dataColumnChartConsMonth = [];

    let keysMonth = [];
    let keysMonthCons = [];

    let groupedDataByMonth = {};
    let groupedDataByMonthCons = {};
    for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
     // Group the daily data into months
     Object.keys(res["daily"]).forEach(key => {
      let [date, , element, metric] = key.split(":");
      let month = date.slice(0, 6); // Extract the month from the date (YYYYMM)
      if (element == arrayOfElements[arrIndex]) {
       if (!groupedDataByMonth[month]) {
        groupedDataByMonth[month] = { calls: 0, answered: 0 };
       }

       // Increment the relevant metric for this month
       if (metric === "calls") {
        groupedDataByMonth[month].calls += parseInt(res["daily"][key]);
       }

       if (metric === "answered") {
        groupedDataByMonth[month].answered += parseInt(res["daily"][key]);
       }
      }
     });

     Object.keys(res["daily*"]).forEach(key => {
      let [date, , , metric] = key.split(":");
      let month = date.slice(0, 6); // Extract the month from the date (YYYYMM)

      if (!groupedDataByMonthCons[month]) {
       groupedDataByMonthCons[month] = { calls: 0, answered: 0 };
      }

      // Increment the relevant metric for this month
      if (metric === "calls") {
       groupedDataByMonthCons[month].calls += parseInt(res["daily*"][key]);
      }

      if (metric === "answered") {
       groupedDataByMonthCons[month].answered += parseInt(res["daily*"][key]);
      }
     });

     // Prepare the data for the column chart
     let months = Object.keys(groupedDataByMonth);

     months.sort(); // Sort months in chronological order

     obj = {};
     objCons = {};
     dataObjAns = {};
     dataObjAnsCons = {};
     dataObjUn = {};
     dataObjUnCons = {};
     dataObjAns["data"] = [];
     dataObjUn["data"] = [];
     dataObjAnsCons["data"] = [];
     dataObjUnCons["data"] = [];
     obj["labels"] = [];
     objCons["labels"] = [];
     months.forEach((month, monthIdx) => {
      dataObjAns["name"] = "Répondus";
      dataObjAns["data"].splice(
       monthIdx,
       1,
       groupedDataByMonth[month].answered
      );
      dataObjAnsCons["name"] = "Répondus";
      dataObjAnsCons["data"].splice(
       monthIdx,
       1,
       groupedDataByMonthCons[month].answered
      );

      dataObjUn["name"] = "Non répondus";
      dataObjUn["data"].splice(
       monthIdx,
       1,
       groupedDataByMonth[month].calls - groupedDataByMonth[month].answered
      );
      dataObjUnCons["name"] = "Non répondus";
      dataObjUnCons["data"].splice(
       monthIdx,
       1,
       groupedDataByMonthCons[month].calls -
        groupedDataByMonthCons[month].answered
      );

      let elementName = "";
      elementName =
       this.urlType === "queue"
        ? res.map[arrayOfElements[arrIndex]]?.name
        : res.map[arrayOfElements[arrIndex]];

      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      obj["labels"].splice(monthIdx, 1, month);

      objCons["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["labels"].splice(monthIdx, 1, month);
     });
     obj["data"] = [dataObjAns, dataObjUn];
     objCons["data"] = [dataObjAnsCons, dataObjUnCons];
     this.extensions.splice(arrIndex, 1, obj["name"]);
     this.dataColumnChartMonth.splice(arrIndex, 1, obj);
     this.dataColumnChartConsMonth.splice(arrIndex, 1, objCons);
    }
    this.$emit("setLoading", false);
   }
  },
  async getDataFromElementForColumnWeekNumber(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;
   this.dataColumnChartWeekNumber = [];
   this.dataColumnChartConsWeekNumber = [];

   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";

   this.extensions.length = 0;

   let pbxElements = undefined;

   if (
    !this.pbxElements &&
    this.$route.query.tableElement &&
    this.isQueryParam
   ) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements);
     this.data = res;
    }

    this.dataColumnChartWeekNumber = [];
    this.dataColumnChartConsWeekNumber = [];

    let groupedDataByWeek = {};
    let groupedDataByWeekCons = {};

    for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
     groupedDataByWeek = {};
     groupedDataByWeekCons = {};
     // Group the daily data into weeks
     Object.keys(res["daily"]).forEach(key => {
      let [date, , element, metric] = key.split(":");
      let week = this.getWeekNumber(date); // Extract the week number from the date (YYYYMMDD)
      if (element == arrayOfElements[arrIndex]) {
       if (!groupedDataByWeek[week]) {
        groupedDataByWeek[week] = { calls: 0, answered: 0 };
       }

       // Increment the relevant metric for this week
       if (metric === "calls") {
        groupedDataByWeek[week].calls += parseInt(res["daily"][key]);
       }

       if (metric === "answered") {
        groupedDataByWeek[week].answered += parseInt(res["daily"][key]);
       }
      }
     });

     Object.keys(res["daily*"]).forEach(key => {
      let [date, , , metric] = key.split(":");
      let week = this.getWeekNumber(date); // Extract the week number from the date (YYYYMMDD)

      if (!groupedDataByWeekCons[week]) {
       groupedDataByWeekCons[week] = { calls: 0, answered: 0 };
      }

      // Increment the relevant metric for this week
      if (metric === "calls") {
       groupedDataByWeekCons[week].calls += parseInt(res["daily*"][key]);
      }

      if (metric === "answered") {
       groupedDataByWeekCons[week].answered += parseInt(res["daily*"][key]);
      }
     });

     // Prepare the data for the column chart
     let weeks = Object.keys(groupedDataByWeek);

     weeks.sort(); // Sort weeks in chronological order

     obj = {};
     objCons = {};
     dataObjAns = {};
     dataObjAnsCons = {};
     dataObjUn = {};
     dataObjUnCons = {};
     dataObjAns["data"] = [];
     dataObjUn["data"] = [];
     dataObjAnsCons["data"] = [];
     dataObjUnCons["data"] = [];
     obj["labels"] = [];
     objCons["labels"] = [];
     weeks.forEach((week, weekIdx) => {
      dataObjAns["name"] = "Répondus";
      dataObjAns["data"].splice(weekIdx, 1, groupedDataByWeek[week].answered);
      dataObjAnsCons["name"] = "Répondus";
      dataObjAnsCons["data"].splice(
       weekIdx,
       1,
       groupedDataByWeekCons[week].answered
      );

      dataObjUn["name"] = "Non répondus";
      dataObjUn["data"].splice(
       weekIdx,
       1,
       groupedDataByWeek[week].calls - groupedDataByWeek[week].answered
      );
      dataObjUnCons["name"] = "Non répondus";
      dataObjUnCons["data"].splice(
       weekIdx,
       1,
       groupedDataByWeekCons[week].calls - groupedDataByWeekCons[week].answered
      );

      let elementName = "";
      elementName =
       this.urlType === "queue"
        ? res.map[arrayOfElements[arrIndex]]?.name
        : res.map[arrayOfElements[arrIndex]];

      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      obj["labels"].splice(weekIdx, 1, week);

      objCons["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["labels"].splice(weekIdx, 1, week);
     });
     obj["data"] = [dataObjAns, dataObjUn];
     objCons["data"] = [dataObjAnsCons, dataObjUnCons];
     this.extensions.splice(arrIndex, 1, obj["name"]);
     this.dataColumnChartWeekNumber.splice(arrIndex, 1, obj);
     this.dataColumnChartConsWeekNumber.splice(arrIndex, 1, objCons);
    }
    this.$emit("setLoading", false);
   }
  },
  async getTimeLabelRange(arr) {
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let start = ("0" + String(sortedArray[0])).slic;
   let end = ("0" + String(sortedArray[arr.length - 1])).slic;
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeStartAndEnd(start, end) {
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourly(arr) {
   let hourlyRange = [];
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let end = "";
   let start = ("0" + String(sortedArray[0]) + "00").slice(0, 4);
   if (String(sortedArray[arr.length - 1]).length > 1) {
    end = (String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   } else {
    end = ("0" + String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   }
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourlyStartAndEnd(start, end) {
   let hourlyRange = [];
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie(
      "API"
     )}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  filterNoData(data) {
   return data?.filter(res => {
    return res.data[0]?.data?.length > 0 || res.data[1]?.data?.length > 0;
   });
  },
 },
 mounted() {
  // if (!this.periodSelector) {
  //  this.periodSelector = this.currentMonthAndYearForPbx;
  // }
  // if (this.pbxPeriodSelected) this.periodSelector = this.pbxPeriodSelected;

  if (this.$route.query.tableElement && this.$route.query.period) {
   this.isQueryParam = true;
  }

  if (this.pbxElementType !== this.urlType)
   this.$store.dispatch("pbxElements", null);

  this.getButtonSelectedFromQueryPeriod();
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostContext",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
   "pbxMap",
  ]),

  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options)
    .format(month)
    .slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
 watch: {
  selected_answered_type: function (val) {
   switch (val) {
    case "hour":
     this.setHourChartData();
    case "day":
     this.setDailyChartData();
    case "week":
     this.setWeeklyChartData();
    case "weekNumber":
     this.setWeeklyChartByWeekNumber();
    case "month":
     this.setMonthlyChartData();
    default:
     return "";
   }
  },
  // is_filtered: function (val) {
  //  switch (this.selected_answered_type) {
  //   case "hour":
  //    this.setHourChartData();
  //   case "day":
  //    this.setDailyChartData();
  //   case "week":
  //    this.setWeeklyChartData();
  //   case "weekNumber":
  //    this.setWeeklyChartByWeekNumber();
  //   case "month":
  //    this.setMonthlyChartData();
  //   default:
  //    return "";
  //  }
  // },
  // periodSelector: function (val) {
  //  this.getDataFromElementForColumn();
  // },
  timeSelected: function (val) {
   //  this.getDataFromElementForColumn(true);
  },
  urlType: function (val) {
   this.$store.dispatch("pbxElements", null);
   this.extensions = [];
  },
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
